/** @jsx jsx */
import { jsx, Styled, Flex, Container, Grid, Button } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => {

  return(
    <Layout>
      <SEO title="About Us" description="The best pillow" />
    <Container sx={{maxWidth: "medium", mt: 6}}>
      <Styled.h1>About Sleep 'N Snooze</Styled.h1>
      <Styled.p>Hi, thanks fro visiting our website! I hope you finding everything well. </Styled.p>
    </Container>
    </Layout>
    
  )
}

export default About;